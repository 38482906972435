.verified-text{
  margin-top:20px;
  color: green;
  font-size: .8rem;
  font-weight: 300;
  span{
    padding-left: 5px;
}
}
.not-verified-text{
  margin-top:20px;
  font-size: .8rem;
  font-weight: 300;
  span{
    padding-left: 5px;
  }
  
}
a.install-plugin {

  padding-top: 6px;

}