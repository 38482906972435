.border-round {
  border-radius: $border-radius;
}
.box-shadow-medium {
  box-shadow: $box-shadow-medium;
}
.box-shadow-large {
  box-shadow: $box-shadow-large;
}

//  Mixins

@mixin rounder($size) {
  width: $size;
  height: $size;
  border-radius: $size/2;
}
