// Custom Variables
$nav-font-size: 1rem;
$header-font-size: 1.2rem;
$border-radius: 5px;
$box-shadow-small: 0 0px 8px rgba(0, 0, 0, 0.16);
$box-shadow-medium: 0 0px 20px rgba(0, 0, 0, 0.16);
$box-shadow-large: 0 0px 25px rgba(0, 0, 0, 0.16) !important;
$primary-color: #303030;
$secondary-color: #606060;
$tertiary-color: #808080;
$theme-orange: #ef7f30;
// Overrides

$navbar-background: #fff;
$global-color: $primary-color;
$global-muted-color: $primary-color;
$button-font-size: $nav-font-size;
$button-primary-background: #5d4cc6;
$button-text-transform: capitalize;
$navbar-nav-item-font-size: $nav-font-size;
$navbar-nav-item-active-color: $theme-orange;
$global-font-family: "Roboto", sans-serif;
$border-rounded-border-radius: 12px;
$base-body-background: #fafafa;
$card-title-font-size: 1rem;
$global-font-size: 15px;
$tab-item-border-width: 2px;
$tab-item-active-border: $button-primary-background;
$tab-item-active-color: $button-primary-background;
$form-radio-checked-background: #fff;
$form-radio-checked-icon-color: $button-primary-background;
$form-radio-checked-focus-background: #fff;
$form-radio-focus-border: $button-primary-background;
$form-radio-checked-border: $button-primary-background;
$internal-form-radio-image: 'data:image/svg+xml;charset=UTF-8,%3Csvg width%3D"32" height%3D"32" viewBox%3D"0 0 16 16" xmlns%3D"http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg"%3E%0A    %3Ccircle fill%3D"%235d4cc6" cx%3D"8" cy%3D"8" r%3D"2" %2F%3E%0A%3C%2Fsvg%3E';
