#popup {
    background: rgba(0, 0, 0, 0.4);
    opacity: 1 !important;
    display: flex !important;
    align-items: center;
    justify-content: center;
    .removed {
      margin: 0;
      background-color: #f6f6f6;
      padding-left: 20px;
      padding-right: 20px;
      border-bottom: none;
    }
  }
  #popup .uk-modal-dialog {
    width: 823px;
    border-radius: 4px;
    opacity: 1;
    min-height: 350px;
    max-height: 80vh;
    overflow-y: scroll;
  }
  #popup .uk-text-bold {
    font-weight: 500;
  }
  #popup .uk-flex-4 {
    flex: 4;
  }
  #popup .uk-flex-3 {
    flex: 3;
  }
  #popup .uk-flex-2 {
    flex: 2;
  }
  #popup .flex-1 {
    flex: 1;
  }
  #popup .orderId {
    flex: 1.15;
  }
  #popup .nameAndMail {
    margin-top: -2px;
  }
  #popup .totalAmount {
    flex: 1.3;
  }
  #popup .uk-flex-1-5 {
    flex-grow: 1;
  }
  #popup .uk-modal-dialog .uk-flex > div p:nth-child(2) {
    margin: 2px 0 0 0;
  }
  #popup .orderIdDetails {
    margin: 10px 20px;
  }
  #popup .items {
    border-bottom: 1px solid rgba(0, 0, 0, 0.1);
    padding: 15px 0;
    margin: 0 20px;
  }
  #popup .items:hover p.productName {
    color: #5444b2;
  }
  #popup .items .uk-flex-1-5 {
    width: 40px;
    padding-left: 50px;
  }
  #popup .items .uk-flex-4 {
    width: 240px;
  }
  #popup .items .uk-flex-4 > div.uk-first-column {
    padding-left: 0;
    box-shadow: 0px 0px 15px 1px rgba(0, 0, 0, 0.15);
    border-radius: 4px;
  }
  #popup .recovered .uk-flex-1-5 {
    color: #d7722b;
  }

  .uk-margin-vertical {
    margin-top: 120px !important;
    margin-bottom: -120px !important;
  }