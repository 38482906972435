.shop-container {
  //width: 500px;
  text-align: justify;
}

.url-check {
  button {
    height: 100%;
    width: 64px;
    border: none;
    border-radius: 30px 0 0 30px;
    background: #F6F6F6;
    outline: 0;
    cursor: pointer;
    box-shadow: 0px 0px 1px 0px rgba(0, 0, 0, 0.5);

    span {
      transform: rotate(45deg);
    }
  }

  input {
    cursor: pointer;
    outline: none;
    border: none;
    width: 200px;
    border-radius: 0 30px 30px 0;
    padding-left: 10px;
    color: #787878;
    box-shadow: 0 0 1px 0 rgba(0, 0, 0, 0.5);
  }
}

.o-button {
  background-color: $theme-orange;
  color: white !important;
  border-radius: 5px;
  font-weight: 600;
  font-size: 1rem !important;
  box-shadow: 0 0 1px 0 rgba(0, 0, 0, 0.5);
  height: 50px;
}
