
.lead-capture{
    .uk-card{
        padding: 20px 15px;
        box-shadow: 0 0px 25px rgba(0, 0, 0, 0.16) !important;
        height: 110px;
        border-radius: 10px;
        .uk-button{
            background: #ef7f30;
            color: #fff;
            border-radius: 6px;
            &:hover{
                color: #ef7f30;
                background: #fff;
                border-color:#ef7f30
            }
        }
        .uk-card-title{
            color: #0d0d88;
            font-weight: 500;
            margin: 5px 0;
            font-size: 1rem;
            .uk-icon{
                width: 16px;
                margin-left: 5px;
            }
        }
        .btn-container{
            padding: 2px 10px;
        }
        .uk-input{
            height: 28px;
            margin-top: 4px;
            border: none;
            border-bottom-color: currentcolor;
            border-bottom-style: none;
            border-bottom-width: medium;
            border-bottom: 1px solid #d9d0d0;
            padding: 0;
            font-size: 15px;
            &::placeholder {
                color: #919191;
            }
        }
        .email-input-block{
            input{
                margin:0;
                height: 23px;
                background: none;
                font-size: 14px;
            }
            .btn-container{
                padding:0;
                margin-top: 5px;
            }
        }
        
    }
    .e-switch-container{
        span{
            padding-left: 10px;
            position: absolute;
        }
    }
    .btn-right-text {
        font-weight: 500;
        font-size: 1.1rem;
        span{
            color: #ef7f30;
            font-size: 1rem;
            margin-left: 5px;
            font-weight: 400px;
            cursor: pointer;
        }
    }
    .customize-widget{
        margin-top:30px;
        .uk-form-label{
            color: #919191;
        }
        .uk-input{
            border-right: none;
            border-top: none;
            border-left: none;
            font-size: 13px;
            &::placeholder {
                color: #919191;
            }
        }
        .color-picker-palette{
            position: absolute;
            z-index: 100;
        }
        .color-picker-cover{
            position: fixed;
            top:0;
            bottom: 0;
            left:0;
            right:0;
        }
        .save-btn{
            background:#ef7f30;
            color: #fff;
        }
        .color-input{
            width: 62px;
            border-radius: 8px;
            cursor: pointer;
        }
    }
    .preview-card{
        .cart-count{
            margin:5px 0;
            span{
                margin: 0 20px;
                font-size: 12px;
            }
        }
        .uk-input{
            border: none;
            border-bottom: 1px solid #cec5c5;
            font-size: 14px;
        }
        .success-msg{
            cursor: pointer;
        }
        .uk-card-title{
            font-size: 1.21rem;
            font-weight: 400;
            color: #303030;
        }
        .prev-title{
            margin: 5px 0;
        }
        .add-cart-btn{
            color: grey;
            background-color: #d3d3d3;
            font-size: 10px;
            margin-left: 14px;
            padding: 0px 10px;
            &:hover{
                border:none;
                color: grey;
                background-color: #d3d3d3;
            }
        }
        .btn-container{
            margin-bottom: 5px;
        }
        .btn-holder{
            button{
                width: 100%;
                margin-top: 5px;
                padding-left: 0;
            }

        }
        .tooltip-icon{
            width: 16px;
            margin-left: 5px;
        }
    }
    .__react_component_tooltip{
        max-width:250px !important;
    }
}

.whatsapp-lead{
    .uk-card {
        height: auto;
    .uk-button{
        padding: 0px 18px;
        background:none;
        color: #312f2f;
        border: none;
        padding: 0;
        font-size: 13px;
        font-weight: 500;
        }
        .add-cart-btn{
            background-color: #d3d3d3;
            padding: 6px 10px;
            font-size: 10px;
            margin-left: 14px;
        }
    }

    .uk-input{
        border: none;
        border-bottom: 1px solid #d9d0d0;
        font-size: 14px;
        height: 30px;
        &::placeholder {
            color: #919191;
        }
    }
    .react-phone-number-input__icon {
        width: 2em;
        height: 1.5em;
        .react-phone-number-input__icon-image{
            margin-bottom: 10px;
        }
    }
    .whatsapp-img{
        width:26px;
        position: relative;
        top: -1px;
    }
}