.e-user-nav {
  height: 100%;
  width: 100%;
  padding-left: 40px;
  margin-left: -100px;
  background-color: #fff;
  border-left: solid 2px #f5f5f5;
  overflow-y: scroll;
  z-index:999;

  .user-image {
    margin-top: 10px;

    .e-upload-container {
      position: relative;;
      width: 55px;
      height: 55px;
    }

    .e-user-icon {
      width: 50px;
      border-radius: 50%;
    }

    .e-upload {
      position: absolute;
      right: -5px;
      width: 40px;
      bottom: -5px;
    }

    .e-image-uploader {
      display: none;
    }
  }

  .verified-text {
    margin-top: 0;
  }

  .e-edit-button {
    margin-top: 20px;
    display: inline-block;
    background-color: #fbf1e9;
    color: #ef7f30;
    padding: 5px 10px 5px 10px;
    border-radius: 20px;
  }

  .e-user-input {
    margin-top: 15px;

    span, .e-dob-title {
      font-size: .8rem;
      color: #888888;
    }


    .e-dob {
      font-weight: 400;
      color: #282828;
    }

    &:focus-within {
      span {
        color: #ef7f30;
      }

      .e-input-value {
        border-bottom: solid 1px #ef7f30;
      }
    }

    .e-input-value {
      border: 0 transparent;
      background-color: transparent;
      padding-bottom: 5px;
    }
    .input-label::after {
      font-family: FontAwesome;
      display: inline-block;
      padding-right: 3px;
      vertical-align: middle;
      content: "\f040";
      padding-left: 5px;
      cursor: pointer;
      font-size:10px;
  }

    img {
      margin-right: 8px;
    }
  }

  .e-button-group {
    .e-button {
      background-color: $theme-orange;
      line-height: 1;
      padding: 6px 10px 6px 10px;
      font-size: .9rem;

      ~ .abort {
        font-size: .9rem;
        color: $tertiary-color;
        margin-left: 15px;
      }
    }
  }

  .e-verify {
    display: flex;
    margin-top: 10px;
    align-items: center;
    color: #606060;

    img {
      width: 18px;
    }

    span {
      padding-left: 10px;
      font-size: .9rem;
    }
  }

  .e-confirm {
    margin-top: 10px;
    color: #5d4bc5;
  }

  .e-password {
    margin-top: 80px;
    background-color: #f6f6f6;
    margin-left: -40px;
    padding-top: 15px;
    padding-bottom: 15px;
    padding-left: 40px;
    font-size: 1.1rem;
    font-weight: 400;
    color: #000;
    cursor: pointer;
  }

  .e-logout {
    margin-top: 15px;
    color: #5d4bc5;
    cursor: pointer;

    .logout-text {
      margin-left: 8px;
    }
  }


}

.e-update-password{

  .e-button-area{
    margin-top:30px;
    margin-bottom:30px;
  }
  .e-button-small {
    min-width: 80px;
  }
  .e-cancel-button{
    margin-left: 15px;
    cursor: pointer;
  }
}

  #password-overlay{
    position: fixed;
    z-index:999;
    opacity:0;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    background: #26242482;
    margin: auto;
    opacity: 0;
        transition: 0.5s linear;
        transition-property: all;
        transition-property:opacity,height;
    height: 0;
    overflow: hidden;
    .e-update-password{
        background: #fff;
        width: 600px;
        max-width:80%!important;
        margin: 50px auto;
        padding: 20px 30px;
        height: auto;
        border-radius: 25px;
        opacity: 0;
        transform: translateY(-100px);
        transition: 0.3s linear;
        transition-property: all;
        transition-property: opacity, transform;
        .e-input{
          margin-top: 25px;
          input{
            width:100%;
          }
        }
  }
    &.open{
      height: 100%;
      opacity:1;
      overflow: scroll;
      .e-update-password{
        opacity: 1;
        transform: translateY(0);
      }
    }
  }