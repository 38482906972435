.register {
  margin: 0;
  background-image: url("../img/bg_shade.png");

  *,
  *::before,
  *::after {
    box-sizing: border-box;
    font-family: "Roboto";
    font-weight: normal;
    text-decoration: none;
    font-size: 1.5vw;
  }

  a {
    color: #000;
  }
  a:hover,
  a li:hover {
    color: #ee802e;
  }

  .nav {
    height: 20vh;
    width: 100%;
    .website_link{
      &:focus{
        outline: none;
      }
    }
  }

  .nav .flex_wrapper {
    padding: 50px 25px 15px 25px;
  }

  .nav .flex_wrapper > img {
    height: 26px;
  }

  .nav > div > ul a {
    font-size: 19px;
  }

  .nav input,
  .nav svg {
    display: none;
  }

  .active_link a {
    padding-bottom: 10px;
    color: #ee802e;
    border-bottom: 2px solid #ee802e;
    text-decoration: none !important;
  }

  .flex_wrapper {
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    flex-wrap: wrap;
  }

  main > .flex_wrapper {
    max-width: 1200px;
    height: fit-content;
    margin: 5vh auto;
    padding: 0 25px;
  }

  .create_account,
  .reg_form {
    height: 100%;
    flex: 1 0;
  }

  .box1 {
    max-width: 70%;
    padding: 8vh 0;
    /* background: yellow; */
  }

  h1 {
    font-size: calc(24px + 1vw);
    font-weight: lighter;
    margin: 2vw 0 1.2vw 0;
  }

  h2 {
    font-weight: lighter;
    line-height: 1.4;
    font-size: calc(9.5px + 1vw);
    margin: 0;
  }

  .box2 {
  }

  .container {
    font-size: 20px;
    position: relative;
    top: 30px;
    padding-left: 35px;
    cursor: pointer;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    vertical-align: top;
  }

  .container input[type="checkbox"] {
    position: absolute;
    opacity: 0;
    left: 0;
    height: 25px;
    width: 25px;
  }

  #checkbox {
    margin-top: 2px;
    margin-left: -27px;
    margin-right: 15px;
    height: 20px;
    width: 20px;
    background-color: #eee;
    transform: translateY(-2px);
  }

  .container:hover input ~ #checkbox {
    background-color: #ccc;
  }

  .container input:checked ~ #checkbox {
    background-color: #2196f3;
  }

  #registernow {
    color: #fdf4ee;
    margin-top: 70px;
    padding: 10px;
    background-color: #ee802e;
    border: solid 1px transparent;
    border-radius: 4px;
    box-shadow: 2px 3px 13px 1px rgba(0, 0, 0, 0.18);
    cursor: pointer;
  }

  #registernow:hover {
    background-color: #d67329;
  }

  #registernow ~ a {
    padding-left: 25px;
  }

  .loginstuff,
  .loginstuff p {
    max-width: 800px;
    margin: auto;
    text-align: center;
    font-size: 70px;
    height: 80vh;
  }

  .disappear {
    display: none;
  }

  footer {
    width: 100%;
    // height: 100px;
  }

  footer li,
  footer p {
    font-size: calc(12px + 0.3vw);
  }

  footer .flex_wrapper,
  .nav .flex_wrapper {
    max-width: 1200px;
    margin: auto;
    justify-content: space-between;
    align-items: center;
  }

  footer .flex_wrapper {
    padding: 25px;
  }

  footer br {
    display: none;
  }

  li {
    display: inline;
    padding: 0 10px;
  }

  @media (max-width: 992px) {
    main > .flex_wrapper > div {
      flex: 1 100%;
    }
    .box1 {
      margin-left: auto;
      margin-right: auto;
      text-align: center;
      max-width: 400px;
      padding: 0 0 50px;
    }
    .box2 {
      max-width: 400px;
      text-align: center;
      margin: auto;
    }
    .box2 form label {
      font-size: 18px;
    }
    .box2 form {
      padding-bottom: 20px;
    }
    #registernow,
    #registernow ~ a {
      font-size: 18px;
    }
    .focused .form-label {
      font-size: 1em;
    }
    .box2 form input {
      height: 35px;
      box-shadow: 0 1px 0 0 #1b0f0f;
    }
    footer .flex_wrapper > * {
      flex: 1 100%;
      text-align: center;
      padding: 0;
    }
    .nav .flex_wrapper {
      justify-content: center;
    }
    .nav ul {
      display: none;
    }
    .nav input,
    .nav svg {
      display: block;
      position: fixed;
    }
    .nav input[type="checkbox"] {
      position: fixed;
      bottom: 43px;
      right: 25px;
      opacity: 0;
      width: 50px;
      height: 50px;
      z-index: 4;
    }
    .nav svg {
      position: fixed;
      bottom: 43px;
      right: 25px;
      stroke: #eeeeee;
      stroke-width: 2;
      width: 6.5em;
      height: 6.5em;
      background: #ee802e;
      border-radius: 1px;
    }
    .nav svg path {
      -webkit-transition: d 0.25s;
      -moz-transition: d 0.25s;
      transition: d 0.25s;
    }
    .nav input:checked ~ svg path {
      d: path("M3.5,3.5 11.5,11.5 M7.5,7.5 7.5,7.5 M3.5,11.5 11.5,3.5");
    }
    .nav input:checked ~ ul {
      position: fixed;
      display: block;
      bottom: 85px;
      right: 20px;
      z-index: 4;
    }
    .nav input:checked ~ ul li {
      padding: 20px;
      display: block;
      text-align: center;
      font-weight: bold;
      background: #ee802e;
      border-bottom: 1px solid rgba(255, 255, 255, 0.24);
    }
    .nav input:checked ~ ul li a {
      color: white;
    }
  }
}

.forgot-email {
  padding-top: 50px;

  .remember-details {
    color: #4920ce;
  }
}

.reset-form{
  input[type="password"]{
    font-size: 1rem;
  }
}



