.e-notification {
  display: inline;
  background-color: #363636;
  padding: 15px;
  border-radius: 10px;
  transition: visibility 0s, opacity 0.5s linear;

  .message {
    color: #ffffff;
    font-size: 15px;
  }

  .close-button {
    color: #fff;
    padding-left: 10px;
    padding-right: 10px;
    cursor: pointer;
  }

  .success-image {
    margin-left: 10px;
    margin-right: 10px;
  }
}


.e-input {

  &.form-group {
    margin-top: 30px;
  }

  &.focused .form-label {
    -webkit-transform: translateY(-115%);
    transform: translateY(-115%);
    font-size: 0.75em;
    color: #ee802e;
  }


  label {
    position: absolute;
    color: #808080;
    z-index: 2;
    margin-top: 10px;
    transition: transform 150ms ease-out, font-size 150ms ease-out,
    -webkit-transform 150ms ease-out;
  }


  input {
    position: relative;
    padding: 12px 0px 5px 0;
    width: 50%;
    background: transparent;
    outline: 0;
    border: 0;
    box-shadow: 0 1px 0 0 #e5e5e5;
  }

  input:focus {
    box-shadow: 0 1px 0 0 #ee802e;
  }

}

.e-nav-right {
  align-items: flex-start !important;

  .e-user-field {
    display: flex;
    align-items: center;
    height: 80px;

    a, svg {
      margin-left: 10px;
    }
  }

}


.e-button {
  background: rgb(238, 94, 15);
  background: linear-gradient(90deg, rgba(238, 94, 15, 1) 0%, rgba(239, 127, 48, 1) 100%);
  color: #fff;
  font-weight: 500;
  font-size: .9rem;
  padding: 10px 12px 10px 12px;
  border: 0;
  border-radius: 5px;

  ~ .abort {
    margin-left: 10px;
    color: #000;
  }
}


.global-notification {
  position: fixed;
  z-index: 1999;
  bottom:50px;
  left:50px;
  display: inline;
  background-color: #363636;
  padding: 15px;
  border-radius: 10px;
  transition: visibility 0s, opacity 0.5s linear;

  .message {
    color: #ffffff;
    font-size: 15px;
  }

  .close-button {
    color: #fff;
    padding-left: 10px;
    padding-right: 10px;
    cursor: pointer;
  }

  .success-image {
    margin-left: 10px;
    margin-right: 10px;
  }
}
