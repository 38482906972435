@import "highcharts/css/highcharts.scss";

.highcharts-legend-item {
  text-overflow: ellipsis;
  .legend-name {
    display: flex;
    span {
      text-align: right;
      padding-right: 10px;
      width: 15px;
    }
  }
}

.highcharts-point {
  stroke-linejoin: miter !important;
}
