.cart-settings {
  .input-width {
    width: 100% !important;
  }
  .uk-form-icon{
    margin-top: 8px;
  }

  .update-button {
    margin-left: 5px;
    padding: 8px 15px 8px 15px;
  }

  .shade-text {
    color: #a8a8a8;
    font-size: .9rem;
    margin-bottom: 8px;

    ~ div {
      margin-top: 10px;
    }

  }
  .input-label::after {
    font-family: FontAwesome;
    display: inline-block;
    padding-right: 3px;
    vertical-align: middle;
    content: "\f040";
    padding-left: 5px;
    cursor: pointer;
    font-size:10px;
}
  .custom-timeout input {
    border: 0;
    border-bottom: solid #a8a8a8 1px;
    width: 30px;
    color: #a8a8a8;
    background-color: transparent;
  }

  .settings-item {
    margin-top: 30px;

    .item-text {
      font-size: .9rem;
    }
  }

  .store-logo {
    @include rounder(100px);
    box-shadow: $box-shadow-small;
    position:relative;
    display: flex;
    align-items: center;
    justify-content: center;

    img {
      width: 70px;
    }
    .store-upload{
      position:absolute;
      width:45px;
      bottom:-5px;
      right:-5px;
    }
    .img-uploader{
      display:none;
    }
  }

  .e-tag {
    padding: 6px;
    background-color: #eae8f6;
    font-size: .8rem;
    color: #6a5bc9;
    font-weight: 400;
    border-radius: 5px;
  }

  .e-card {
    padding-top: 20px;
    padding-bottom: 20px;
    padding-left: 20px;
  }

  .shop-name {
    padding-bottom: 8px;
    font-size: .9rem;
  }

  .add-logo {
    border: solid 1px #f0f0f0;
    background-color: #fafafa;
    padding: 25px 20px 25px 20px;

    ~ .text-details {
      padding-left: 15px;
      display: flex;
      flex-direction: column;
      justify-content: center;

      .header {
        color: #000;
        font-size: 1.1rem;
        font-weight: 400;
      }

      .sub {
        color: #a8a8a8;
        font-size: .9rem;
      }
    }
  }

}
.react-confirm-alert-overlay{
  z-index: 9999!important;
}
.switch-shops{
  .add-shop-btn {
    background: #ef7f30;
    color: #fff;
    padding: 5px 8px;
    border-radius: 5px;
    text-decoration: none;
    font-size: 0.8rem;
  }
  .shop-card {
    padding: 25px;
    color: #606060;
    box-shadow: 0 0px 25px rgba(0, 0, 0, 0.16) !important;
    height: 150px;
    border-radius: 10px;
       h3 {
          margin-top: 15px;
          margin-bottom: 0;
      }
      .uk-icon {
        float: right;
        cursor:pointer;
    }
    .more-option{
      float: right;
    }
    .uk-dropdown{
      padding: 10px 0 0 0;
      margin: 0;
      min-width: 125px;
      width: auto;
      .uk-list {
        margin: 0px;
        padding:0 15px 10px 15px;
        a{
          text-decoration: none;
          color: #606060;
          &:hover {
            color:#faa05a;
          }
        }
      }
    }
    .default-msg{
      margin-right: 15px;
      font-size: 12px;
      margin-left: 10px;
      float:right;
      img{
        margin-right: 5px;
      }
    }
    .switch-block{
      label{
        cursor: pointer;
      }
      font-size: 12px;
      .uk-radio{
          margin-right: 5px;
        }
    }
  }
}

