.sort-by-cart-value {
  margin-right: 10px;
  color: $tertiary-color;
}
.not-found-msg{
  padding: 0 5px;
  font-size: 18px;
  text-align: center;
  span{
    margin-right: 5px;
    svg{
      width: 30px;
    }
  }
}
.cart-item {
  flex-basis: 80px;
  overflow: hidden;
  font-size: 0.9rem;
  &.prd-item{
    padding:5px;
    margin-bottom: 5px;
    .image-header{
      img{
        width:50px;
        height: 50px;
      }
    }
  }
  .name-container {
    > div {
      padding-left: 15px;
    }
  }

  .image-header {
    text-align: center;
  }

  > div {
    white-space: nowrap;
    overflow: hidden;

    // Removing item image from styling
    img {
      &.item-image {
        border-radius: 50%;
        height: 60px;
      }
      &:not(.item-image,.arrow-image, .cart-image) {
        width: 14px;
        margin-right: 5px;
      }
      &.arrow-image {
          width: 5px;
          //margin-left: 100px;
        }
        &.cart-image {
          margin-left: 4px;
          margin-bottom: 5px;
        }
    }

    .details-header {
      height: auto;

      > div {
        overflow: hidden;
        text-overflow: ellipsis;
        padding-right: 15px;
      }
    }
    .details-footer {
      overflow: hidden;
      text-overflow: ellipsis;
      padding-right: 15px;
    }

    .more-info,
    .user-details {
      color: $navbar-nav-item-active-color;
    }
    .send-mail {
      color: $button-primary-background;
    }
    .cart-item-name {
      color: $primary-color;
      font-weight: 500;
    }
    .cart-item-amount {
      color: $secondary-color;
      margin-left: 25px;
    }
    .buyer-name {
      color: $primary-color;
    }
    .buyer-email {
      color: $tertiary-color;
      padding-left: 19px;
      font-size: 0.8rem;
    }
    .qty {
      color: $tertiary-color;
    }
    .buyer-footer {
      padding-left: 19px;
      span {
        &:first-child {
          padding-right: 15px;
        }
      }
    }

    &:nth-child(1n) {
      flex: 0.1;
    }
    &:nth-child(2n) {
      // .3 remaining
      flex: 0.22;
    }
    &:nth-child(3n) {
      flex: 0.28;
    }
    &:nth-child(4n) {
      flex: 0.1;
    }
    &:nth-child(5n) {
      flex: 0.21;
    }
    &:nth-child(6n) {
      flex: 0.17;
    }
  }
}
.abn-cart-item{
  div{
    &.buyer-email{
      padding-left: 0;
    }
    &:nth-child(1n) {
      flex: 0.3;
    }
    &:nth-child(2n) {
      // .3 remaining
      flex: 0.3;
    }
    &:nth-child(3n) {
      flex: 0.15;
    }
    &:nth-child(4n) {
      flex: 0.25;
      position: relative;
      a{
        position: relative;
        float: right;
        margin-top: -22px;
        margin-right: 10px;
      }
    }
  }
}

@media (max-width: 900px) {
  .cart-item {
    flex-basis: 80px;
    display: block !important;
    > div {
      width: 100%;
      text-align: center;
    }
  }
  .abn-cart-item{
    div{
      &:nth-child(4n) {
        a{
          position: relative;
          float: none;
          margin-top:auto;
          margin-right:0;
        }
      }
    }
  }
}
