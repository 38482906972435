@import "./home/abandon-cart.scss";
@import "./home/shop.scss";
@import "./home/piechart.scss";

.uk-navbar-left {
  .uk-navbar-nav {
    .uk-active > a {
      border-bottom: 2px solid $navbar-nav-item-active-color;
    }

    li a {
      text-transform: capitalize;
    }
  }

  .uk-logo > img {
    width: 150px;
  }
}

.uk-navbar-right {
  > img {
    width: 30px;
  }

  .user-name {
    font-size: $nav-font-size;
    padding-left: 5px;
  }
}

.cart-details {
  .e-settings-button{

    img{
      width:15px;
      margin-right: 3px;
    }
  }

  .cart-nav {
    margin-top: 15px;
  }

  .cart-container {
    margin-top: 60px;

    .uk-nav {
      padding-top: 15px;
      padding-bottom: 15px;

      li {
        &.uk-active {
          background: #eae8f6;
          color: $button-primary-background;

          a {
            color: $button-primary-background;
          }
        }

        font-size: 0.9rem;
        padding-top: 5px;
        padding-bottom: 5px;
      }
    }
  }

  .cart-info {
    padding-top: 50px;
    padding-bottom: 20px;

    .uk-card-title {
      font-size: 1.1rem;
      color: #000;
      margin: 0;
    }

    .sub-title {
      color: #606060;
      margin-top: 2px;
    }

    .uk-button {
      padding: 2px 15px;
      line-height: inherit;
    }

    .card-details {
      padding-top: 20px;
      margin-top: 10px;
      img{
        width:12px;
        vertical-align: baseline;
      }
      a {
        padding-left: 6px;
        color: $theme-orange;
        font-size: 0.9rem;
      }
    }
  }
}

.cart-summary {
  .header-bar {
    .mail,
    .print {
      padding-left: 15px;
      color: $navbar-nav-item-active-color;

      img {
        padding-right: 10px;
      }
    }

    .report-header {
      color: #000;
      font-size: $header-font-size;
      font-weight: 500;
    }
  }

  .cart-cards {
    h3 {
      margin-top: 15px;
      margin-bottom: 0;
      color: #606060;
    }

    p {
      color: #000;
      font-size: 1.1rem;
      margin: 0;
      font-weight: 500;
    }

    .uk-card {
      height: 130px;
      padding-top: 20px;
      box-shadow: $box-shadow-large;
    }
  }
}
.todo-list{
  li{
    margin-bottom: 10px;
  }
}
.table {
  tbody {
    .spacer {
      height: 10px;
    }

    .user-count {
      color: $navbar-nav-item-active-color;
    }
  }
}

.searchbar-container {
  width: 260px;
  margin-right: 50px;

  .uk-search-icon {
    padding-right: 15px;
    padding-left: 15px;
  }

  input {
    padding-left: 20px !important;
  }
}

.graph-scale {
  input {
    margin-right: 5px;
  }
  .custom-picker{    
    padding: 10px;
    margin-top: -10px;
    margin-left: 10px;
    
    .react-datepicker-wrapper{
      border: solid 1px #ccc;
      border-radius: 4px;
      margin-left: 10px;
      
    }

    input[type=text]{
      border:none;
      padding-left: 5px;
    }
  }
}

.image-container {
  position: relative;

  .round-logo {
    @include rounder(100px);
    background: #fff url("../img/wf.png") no-repeat center;
    background-size: 70%;
    box-shadow: $box-shadow-small;
    position: absolute;
    z-index: 100;
    margin: auto;
    left: 0;
    right: 0;

    .plus {
      @include rounder(28px);
      position: absolute;
      background: $navbar-nav-item-active-color;
      bottom: 0;
      right: 0;
      color: #fff;
      font-weight: 500;
      font-size: 1.5rem;
      line-height: 1.3;
      text-align: center;
      text-decoration: none;
    }
  }
}