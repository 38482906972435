.subscribers{
    .header-bar {
        .mail{
            cursor:pointer;
            padding-left: 15px;
            color: #ef7f30;
            img{
                padding-right:10px;
            }
        }
    }
    table{
        td{
            vertical-align: middle;
            .prd-image{
                text-align: center;
            }
            img{
                width:50px;
                border-radius: 50%;
            }
        }
    }
    .subscriber-item{
        padding: 5px;
        margin-bottom:10px;
        box-shadow: 0 0px 8px rgba(0, 0, 0, 0.16)!important;
        div{
        .item-image{
            width:50px;
            height: 50px;
        }
          &.buyer-email{
            padding-left: 0;
          }
          &:nth-child(1n) {
            flex: 0.4;
          }
          &:nth-child(2n) {
            // .3 remaining
            flex: 0.3;
          }
          &:nth-child(3n) {
            flex: 0.3;
          }
        }
      }
}
.react-confirm-alert{
    border-radius: 30px;
    .react-confirm-alert-body{
        h1{
            margin:0;
            font-size: 1.8rem;
        }
        .react-confirm-alert-button-group{
          button{
              background: #ef7f30 !important;
            }
        }
    }
}

@media (max-width: 900px) {
    .subscriber-item{
        .details-header{
            display: block;
            .item-image{
                margin:auto !important;
            }
        }
    }
}
@media (max-width: 767px){
    .subscribers{
        table{
            td{
                font-size:10px;
                .prd-name{
                   text-align: center;
                    margin-top: 10px;
                }
            }
        }
    }
}