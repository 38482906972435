.mail-settings {

  .e-heading {
    font-size: 1.2rem;
    color: #000;
  }

  .e-subheading {

  }

  .mail_settings {

    .uk-tab > .uk-active > a {
      color: #ee802e;
      border-color: #ef7f30;
    }

    [uk-tab] {
      width: fit-content;
    }
  }


  .firstfollowuptab {
    .change_time {
      color: #5D4CC6;
    }

    .change_template a {
      color: rgba(239, 127, 48, 0.4);
    }

    .uk-card {
      max-width: 300px;
      padding: 5px 0 0 0;
      box-shadow: 0 1px 15px 5px rgba(0, 0, 0, .1);
    }
  }

  .template_choose_box {
    background: #F6F6F6;
    margin: 15px;

    .choosenowtemplate {
      padding: 10px 20px;
      border: none;
      border-radius: 4px;
      background: #EF7F30;
      color: white;
      font-size: 18px;
      box-shadow: 0px 4px 7px 0px rgba(0, 0, 0, 0.15);
    }
  }


  .remove_editcontent {
    width: 100%;
    box-shadow: 0px -5px 5px 0px rgba(0, 0, 0, 0.09);
    padding: 10px 0 1px 0;

    p {
      display: flex;
      justify-content: space-between;
      padding-left: 15px;
      padding-right: 15px;
    }

    img {
      margin-right: 3px;
      color: #ee802e;
    }

    .x_remove {
      color: #ee802e;
    }
  }

  #white_overlay {
    position: absolute;
    background: rgba(255, 255, 255, 0.6);
    height: 56px;
    bottom: 0;
    left: 0;
    right: 0;
  }

  .mailing_details {
    background: #FBFBFB;
    max-width: 250px;
    border-radius: 4px;
    border: 1px solid rgba(0, 0, 0, 0.09);
    padding: 30px;

    h4 {
      margin-bottom: 0px;
    }

    .e-mail-details-input {
      border: none;
      background-color: transparent;
    }

    .email-heading {
      margin-bottom: 10px;
      img{
        cursor:pointer;
      }
    }
    .input-label {
      margin: 0;
    }
    .input-label::after {
      font-family: FontAwesome;
      display: inline-block;
      padding-right: 3px;
      vertical-align: middle;
      content: "\f040";
      padding-left: 5px;
      cursor: pointer;
      font-size:10px;
  }

  }

  #sidebar_box2 {
    width: 200px;
    padding: 0;
    margin-top: 60px;

    li {
      padding: 7px 25px;
      color: #757575;
      margin: 5px 0;

      &:hover {
        background: #EAE8F6;
        color: #7B6DCF;
      }
    }
  }


}

.follow-up{
  .e-switch-container{
    display: flex;
    span{
      margin-left: 10px;
    }
  }
  .trigger-time{
    font-weight: 500;
    margin-left: 10px;
  }
}

.follow-up-input{
  display: flex;
  margin-left: 20px;
  border-bottom: solid 1px #ccc;
  font-size: 1rem;
  padding-bottom:5px;
  input{
    font-family: $global-font-family;
    font-size: 1rem;
    padding-left: 5px;
    border: none;
    background: transparent;
    width: 22px;
  }
  &.hours{
    input{
      width: 42px;
    }
  }

  &.minutes{
    input{
      width: 42px;
    }
  }
}